import React from "react";

import logo from "../assets/images/Logo-ASM.png";
import social from "../assets/images/social.png";
import mobileLogo from '../assets/images/mobile-logo.png'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

function Footer() {
    return (
        <>
            <div className="footer">
                <div className="container">
                    <div className="footer-top">
                        <a href="#" className="logo">
                            <img src={logo} alt="logo" />
                        </a>
                        <a href="#" className="mobile-logo">
                            <img src={mobileLogo} alt="logo" />
                        </a>
                        {/*<a href="#" className="social">
                            <img src={social} alt="" />
                        </a>*/}
                        <div>
                            <FacebookIcon style={{color:"#ffffff", marginRight:"30px"}}/>
                            <InstagramIcon style={{color:"#ffffff", marginRight:"30px"}}/>
                            <TwitterIcon style={{color:"#ffffff"}}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyrights © 2021. Al Seer Marine. All Rights Reserved</p>
            </div>
        </>
    );
}

export default Footer;
